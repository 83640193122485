import { useEffect, useRef, MutableRefObject } from "react";
import { useRefs } from "../components/RefProvider";

export const useRegisterRef = (
  id: string,
): MutableRefObject<HTMLDivElement | null> => {
  const ref = useRef<HTMLDivElement>(null);
  const { registerRef } = useRefs();

  useEffect(() => {
    registerRef(id, ref);
  }, [id, registerRef]);

  return ref;
};
