import useWrappedSWR from "swr";
import { z } from "zod";
import { fetcher } from "../util/fetcher";

const ConnectionSchema = z
  .object({
    from_id: z.string(),
    to_id: z.string(),
  })
  .transform(({ from_id, to_id }) => ({
    fromId: from_id,
    toId: to_id,
  }));

type Connection = z.output<typeof ConnectionSchema>;

const ConnectionsSchema = z.array(ConnectionSchema);

const useConnections = () => {
  const { data, error, isLoading } = useWrappedSWR("connections", fetcher);
  return {
    connections: error || isLoading ? [] : ConnectionsSchema.parse(data),
    error,
    isLoading,
  };
};

export type { Connection };
export { useConnections };
