import { AllFunnelsContainer } from "./components/FunnelContainer";
import { TouchpointCard } from "./components/Touchpoint";
import { GridPaper } from "./components/GridPaper";
import { RefProvider } from "./components/RefProvider";
import { Network } from "./components/Network";
import { useDestinations } from "./hooks/useDestinations";
import { useState } from "react";
import { Integration } from "./components/Integration";

enum FocusedMetric {
  Sessions,
  Events,
  KeyEvents,
}

const App = () => {
  const { destinations } = useDestinations();

  const [focusedMetric, setFocusedMetric] = useState(FocusedMetric.Sessions);

  return (
    <div className="flex flex-col h-screen min-w-fit">
      <header className="bg-white flex gap-4 h-16 w-full items-center justify-between border border-slate-200 p-4">
        <div className="select-none flex gap-2 items-center">
          <div className="text-slate-800 text-lg">CASE EV Campaign 2024</div>
          <div className="text-sky-400 bg-sky-100 w-fit h-fit p-0.5 px-1 rounded-md italic text-sm border border-transparent hover:border-sky-200">
            Interactive
          </div>
        </div>
        <div className="flex gap-2">
          <Integration
            name="Google Sheets"
            icon="table"
          />
          <Integration
            name="Google Analytics"
            icon="google"
          />
        </div>
      </header>
      <GridPaper className="fixed inset-0 -z-10 h-full w-full" />
      <main className="p-8 flex h-full w-screen overflow-x-auto">
        <div className="mx-auto grid grid-cols-12 gap-16 h-fit w-full max-w-[2400px] min-w-[1200px] ">
          <RefProvider>
            <AllFunnelsContainer
              className="col-span-3 flex h-full flex-col gap-8 justify-center"
              focusedMetric={focusedMetric}
              setFocusedMetric={setFocusedMetric}
            />
            {[2, 3, 4].map((lane) => (
              <div
                key={lane}
                className="col-span-3 gap-8 flex flex-col justify-center"
              >
                {destinations
                  .filter((d) => d.lane === lane)
                  .map((destination, idx) => (
                    <TouchpointCard
                      key={idx}
                      className="w-full"
                      destination={destination}
                      focusedMetric={focusedMetric}
                      setFocusedMetric={setFocusedMetric}
                    />
                  ))}
              </div>
            ))}
            <Network className="-z-10" />
          </RefProvider>
        </div>
      </main>
    </div>
  );
};

export { App, FocusedMetric };
