import useSWR from "swr";
import useSWRImmutable from "swr/immutable";
import { baseUrl } from "../util/fetcher";

enum IntegrationStatus {
    LIVE,
    WAITING,
    DOWN,
}

const INTEGRATION_STATUS_BASE_ENDPOINT = `${baseUrl}/integration_status_check`;
const INTEGRATION_PULL_BASE_ENDPOINT = `${baseUrl}/integration_pull`;

const useIntegrationStatus = (name: string) => {
    const INTEGRATION_STATUS_ENDPOINT = `${INTEGRATION_STATUS_BASE_ENDPOINT}?name=${name}`;
    const { data, error, isValidating } = useSWR(
        INTEGRATION_STATUS_ENDPOINT,
        async () => {
            let data = await fetch(INTEGRATION_STATUS_ENDPOINT);
            return data.json();
        },
        {
            revalidateIfStale: false,
            revalidateOnFocus: false,
            refreshInterval:
                window.__RUNTIME_CONFIG__.INTEGRATION_STATUS_CHECK_INTERVAL_MS,
        },
    );
    return {
        status: {
            status: data?.success
                ? IntegrationStatus.LIVE
                : IntegrationStatus.DOWN,
            lastCheck: data?.last_check,
        },
        error,
        isValidating,
    };
};

const useIntegration = (name: string) => {
    const INTEGRATION_PULL_ENDPOINT = `${INTEGRATION_PULL_BASE_ENDPOINT}?name=${name}`;
    const { isValidating } = useSWRImmutable(
        INTEGRATION_PULL_ENDPOINT,
        async () => {
            await fetch(INTEGRATION_PULL_ENDPOINT, { method: "POST" });
        },
        { revalidateOnMount: false },
    );
    return {
        isValidating,
    };
};

export {
    useIntegration,
    useIntegrationStatus,
    IntegrationStatus,
    INTEGRATION_STATUS_BASE_ENDPOINT,
    INTEGRATION_PULL_BASE_ENDPOINT,
};
