import useWrappedSWR from "swr";
import { z } from "zod";
import { fetcher } from "../util/fetcher";

const TrafficMetricsSchema = z
  .object({
    total_events: z.number(),
    total_key_events: z.number(),
    total_sessions: z.number(),
  })
  .transform(({ total_events, total_key_events, total_sessions }) => ({
    totalEvents: total_events,
    totalKeyEvents: total_key_events,
    totalSessions: total_sessions,
  }));

const DestinationIncomingTrafficSchema = z.record(
  z.string(),
  TrafficMetricsSchema,
);

type TrafficMetrics = z.output<typeof TrafficMetricsSchema>;
type DestinationIncomingTraffic = z.output<
  typeof DestinationIncomingTrafficSchema
>;

const useDestinationIncomingTraffic = (destinationName: string) => {
  const uri = `dest_incoming_traffic?dest_name=${destinationName}`;
  const { data, error, isLoading } = useWrappedSWR(uri, fetcher);
  return {
    destinationIncomingTraffic:
      error || isLoading ? [] : DestinationIncomingTrafficSchema.parse(data),
    error,
    isLoading,
  };
};

export type { DestinationIncomingTraffic, TrafficMetrics };
export { useDestinationIncomingTraffic, TrafficMetricsSchema };
