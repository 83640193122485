import React from "react";
import { format } from "d3-format";

const Metric = ({
  name,
  value,
  className,
  isActive,
  onClick,
}: {
  name: string;
  value: number;
  isActive: boolean;
  onClick: () => void;
  className?: string;
}) => {
  return (
    <button
      className={`${className} flex items-center w-full justify-center flex-col 
    p-2 rounded-md ${isActive ? "bg-slate-200" : "hover:bg-slate-100"}`}
      onClick={() => onClick()}
    >
      <div className="text-slate-700 font-bold text-lg">
        {format("0.3~s")(value)}
      </div>
      <div className="text-slate-500">{name}</div>
    </button>
  );
};

export { Metric };
