import { useRegisterRef } from "../hooks/useRegisterRef";
import { format } from "d3-format";
import { useFunnels } from "../hooks/useFunnels";
import { TrafficMetrics } from "../hooks/useDestinationIncomingTraffic";
import { useSpring, animated } from "@react-spring/web";

const PageChip = ({
  pageName,
  pageIncomingTraffic,
  metricValue,
  trafficMetricValue,
  proportion,
  className,
}: {
  pageName: string;
  pageIncomingTraffic: Record<string, TrafficMetrics>;
  metricValue: number;
  trafficMetricValue: (t: TrafficMetrics) => number;
  proportion: number;
  className?: string;
}) => {
  const pct = proportion * 100;

  const value = format("0.2~s")(metricValue);
  const ref = useRegisterRef(pageName);
  const { funnels } = useFunnels();

  const getProportion = (funnelName: string) => {
    const funnelValue =
      pageIncomingTraffic && Object.hasOwn(pageIncomingTraffic, funnelName)
        ? trafficMetricValue(pageIncomingTraffic[funnelName])
        : 0;
    return metricValue > 0 ? (pct * funnelValue) / metricValue : 0;
  };

  const createGradient = () =>
    [
      ...funnels.map(({ funnelName, color }, index) => {
        const proportion = getProportion(funnelName);
        const previousProportion =
          index === 0 ? 0 : getProportion(funnels[index - 1].funnelName);
        return `${color}88 ${previousProportion}%, ${color}88 ${proportion}%`;
      }),
      `#ffffff00 ${getProportion(funnels[funnels.length - 1].funnelName)}%`,
    ].join(", ");

  const gradient = createGradient();

  const springProps = useSpring({
    background: `linear-gradient(to right, ${gradient})`,
  });

  return (
    <animated.div
      ref={ref}
      className={`${className} rounded-md flex h-7 py-1 px-2 justify-between items-center text-sm text-slate-800`}
      style={springProps}
    >
      <div className="flex items-center gap-2">
        <div className="whitespace-nowrap truncate">{pageName}</div>
      </div>
      <div className="font-semibold">{value}</div>
    </animated.div>
  );
};

export { PageChip };
