import useWrappedSWR from "swr";
import { z } from "zod";
import { fetcher } from "../util/fetcher";

const FunnelSchema = z
  .object({
    funnel_name: z.string(),
    color: z.string(),
    icon: z.string(),
  })
  .transform(({ funnel_name, color, icon }) => ({
    funnelName: funnel_name,
    color: color,
    icon: icon,
  }));

type Funnel = z.output<typeof FunnelSchema>;

const FunnelsSchema = z.array(FunnelSchema);

const useFunnels = () => {
  const { data, error, isLoading } = useWrappedSWR("funnels", fetcher);
  return {
    funnels: error || isLoading ? [] : FunnelsSchema.parse(data),
    error,
    isLoading,
  };
};

export type { Funnel };
export { useFunnels };
