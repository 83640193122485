import { animated, useSpring } from "@react-spring/web";
import { useRegisterRef } from "../hooks/useRegisterRef";
import { Tactic } from "../hooks/useTactics";
import { getPhosphorIcon } from "../util/getPhosphorIcon";
import { format } from "d3-format";

const TacticChip = ({
  tactic,
  proportion,
  metricValue,
  className,
}: {
  tactic: Tactic;
  proportion: number;
  metricValue: number;
  className?: string;
}) => {
  // Need to get this data from a hook
  const pct = Math.round(proportion * 100);

  const { tacticName, color, icon } = tactic;
  const LeftIcon = getPhosphorIcon(icon);

  const value = format("0.2~s")(metricValue);
  const ref = useRegisterRef(tacticName);

  const springProps = useSpring({
    background: `linear-gradient(to right, ${color}99 ${pct}%, ${color}50 ${pct}%)`,
  });

  return (
    <animated.div
      ref={ref}
      className={`${className} rounded-md flex h-7 py-1 px-2 justify-between items-center text-sm text-slate-800`}
      style={springProps}
    >
      <div className="flex items-center gap-2">
        <LeftIcon weight="regular" size={18} />
        <div className="whitespace-nowrap truncate">{tacticName}</div>
      </div>
      <div className="font-semibold">{value}</div>
    </animated.div>
  );
};

export { TacticChip };
