const GridPaper = ({ className }: { className?: string }) => {
  return (
    <svg
      className={`${className}`}
      width="100%"
      height="100%"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <pattern
          id="smallGrid"
          width="25"
          height="25"
          patternUnits="userSpaceOnUse"
        >
          <path
            d="M 25 0 L 0 0 0 25"
            fill="none"
            stroke="gray"
            strokeWidth="0.1"
          />
        </pattern>
        <pattern
          id="grid"
          width="100"
          height="100"
          patternUnits="userSpaceOnUse"
        >
          <rect width="100" height="100" fill="url(#smallGrid)" />
          <path
            d="M 100 0 L 0 0 0 100"
            fill="none"
            stroke="gray"
            strokeWidth="0.12"
          />
        </pattern>
      </defs>
      <rect width="120%" height="120%" fill="url(#grid)" />
    </svg>
  );
};

export { GridPaper };
