import { useRegisterRef } from "../hooks/useRegisterRef";
import { Destination } from "../hooks/useDestinations";
import {
  TrafficMetrics,
  useDestinationIncomingTraffic,
} from "../hooks/useDestinationIncomingTraffic";
import { FocusedMetric } from "../App";
import { PageChip } from "./PageChip";
import { Metric } from "./Metric";
import { useFunnels } from "../hooks/useFunnels";
import { useIncomingTrafficByPage } from "../hooks/usePageIncomingTraffic";
import { animated, useTransition } from "@react-spring/web";

const TouchpointCard = ({
  setFocusedMetric,
  destination,
  focusedMetric,
  className,
}: {
  destination: Destination;
  focusedMetric: FocusedMetric;
  setFocusedMetric: (x: FocusedMetric) => void;
  className?: string;
}) => {
  const { destName, imageUri } = destination;
  const ref = useRegisterRef(destName);

  const { funnels } = useFunnels();
  const { destinationIncomingTraffic } = useDestinationIncomingTraffic(
    destination.destName,
  );
  const { incomingTrafficByPage } = useIncomingTrafficByPage(
    destination.destName,
  );

  const calculateTotal = (
    key: "totalSessions" | "totalEvents" | "totalKeyEvents",
  ) =>
    destinationIncomingTraffic
      ? Object.values(destinationIncomingTraffic).reduce(
          (a, d) => d[key] + a,
          0,
        )
      : 0;

  const totalSessions = calculateTotal("totalSessions");
  const totalEvents = calculateTotal("totalEvents");
  const totalKeyEvents = calculateTotal("totalKeyEvents");

  const trafficMetricValue = (t: TrafficMetrics) => {
    const lookup: Record<FocusedMetric, number> = {
      [FocusedMetric.Sessions]: t.totalSessions,
      [FocusedMetric.KeyEvents]: t.totalKeyEvents,
      [FocusedMetric.Events]: t.totalEvents,
    };
    return lookup[focusedMetric];
  };

  const trafficMetricProportion = (t: TrafficMetrics) => {
    const lookup: Record<FocusedMetric, number> = {
      [FocusedMetric.Sessions]: t.totalSessions / totalSessions,
      [FocusedMetric.KeyEvents]: t.totalKeyEvents / totalKeyEvents,
      [FocusedMetric.Events]: t.totalEvents / totalEvents,
    };
    return lookup[focusedMetric];
  };
  let height = 0;
  const heightPerChild = 28;
  const transitions = useTransition(
    Object.entries(destinationIncomingTraffic)
      .sort(([_, a], [__, b]) => trafficMetricValue(b) - trafficMetricValue(a))
      .map(([pageName, totalTraffic]) => ({
        pageName,
        totalTraffic,
        y: (height += heightPerChild) - heightPerChild,
      })),
    {
      keys: (item) => item.pageName,
      from: { height: 0, opacity: 0 },
      leave: { height: 0, opacity: 0 },
      enter: ({ y }) => ({ y, opacity: 1 }),
      update: ({ y }) => ({ y }),
    },
  );
  return (
    <div
      ref={ref}
      className={`${className} flex flex-col bg-slate-100 rounded-md`}
    >
      <div className="p-2 text-slate-800 font-semibold flex justify-between items-center">
        {destName}
      </div>
      <div
        className="w-full h-48 bg-cover bg-center"
        style={{ backgroundImage: `url(${imageUri})` }}
      />
      <div className="flex justify-around h-16 text-sm text-slate-400 p-1 gap-2 px-2">
        <Metric
          name={"Sessions"}
          value={totalSessions}
          isActive={focusedMetric === FocusedMetric.Sessions}
          onClick={() => setFocusedMetric(FocusedMetric.Sessions)}
        />
        <Metric
          name={"Events"}
          value={totalEvents}
          isActive={focusedMetric === FocusedMetric.Events}
          onClick={() => setFocusedMetric(FocusedMetric.Events)}
        />
        <Metric
          name={"Key Events"}
          value={totalKeyEvents}
          isActive={focusedMetric === FocusedMetric.KeyEvents}
          onClick={() => setFocusedMetric(FocusedMetric.KeyEvents)}
        />
      </div>
      <div className="flex col-span-6 justify-between items-center truncate px-2 italic flex-wrap">
        <div className="italic text-slate-600 text-sm">
          EV Campaign-Related Traffic
        </div>
        <div className="flex gap-1">
          {funnels.map(({ funnelName, color }, idx) => (
            <div
              key={idx}
              className="flex text-xs items-center text-slate-500 gap-0.5"
            >
              <div
                className="h-2 w-3 rounded-lg"
                style={{ backgroundColor: `${color}bb` }}
              />
              {funnelName}
            </div>
          ))}
        </div>
      </div>
      <div
        className="flex flex-col gap-2 p-2"
        style={{
          height:
            (heightPerChild + 8) *
              Object.keys(destinationIncomingTraffic).length +
            8,
        }}
      >
        {transitions((style, { pageName, totalTraffic }, _t, index) => {
          return (
            <animated.div
              key={index}
              style={{
                zIndex: Object.keys(destinationIncomingTraffic).length - index,
                ...style,
              }}
            >
              <PageChip
                key={index}
                pageName={pageName}
                pageIncomingTraffic={incomingTrafficByPage[pageName]}
                metricValue={trafficMetricValue(totalTraffic)}
                trafficMetricValue={trafficMetricValue}
                proportion={trafficMetricProportion(totalTraffic)}
              />
            </animated.div>
          );
        })}
      </div>
    </div>
  );
};

export { TouchpointCard };
