import { useCallback, useEffect, useState } from "react";
import { useRefs } from "./RefProvider";
import { Arrow } from "./Arrow";
import { useConnections } from "../hooks/useConnections";

enum Region {
  BOTTOM_LEFT = "BOTTOM_LEFT",
  // BOTTOM_MIDDLE = "BOTTOM_MIDDLE",
  // BOTTOM_RIGHT = "BOTTOM_RIGHT",
  // TOP_LEFT = "TOP_LEFT",
  // TOP_MIDDLE = "TOP_MIDDLE",
  // TOP_RIGHT = "TOP_RIGHT",
  // LEFT_TOP = "LEFT_TOP",
  LEFT_MIDDLE = "LEFT_MIDDLE",
  // LEFT_BOTTOM = "LEFT_BOTTOM",
  // RIGHT_TOP = "RIGHT_TOP",
  RIGHT_MIDDLE = "RIGHT_MIDDLE",
  // RIGHT_BOTTOM = "RIGHT_BOTTOM",
}

interface ConnectionSpec {
  id: string;
  region: Region;
}

interface Coordinate {
  x: number;
  y: number;
}

interface Placement {
  from: Coordinate;
  to: Coordinate;
}

const mid = (a: number, b: number) => (a + b) / 2;

const connectionCalc: {
  [K in keyof typeof Region]: (r: DOMRect) => Coordinate;
} = {
  [Region.BOTTOM_LEFT]: ({ bottom, left }) => ({ x: left, y: bottom }),
  [Region.LEFT_MIDDLE]: ({ top, bottom, left }) => ({
    x: left,
    y: mid(top, bottom),
  }),
  [Region.RIGHT_MIDDLE]: ({ top, bottom, right }) => ({
    x: right,
    y: mid(top, bottom),
  }),
};

const calculatePosition = (
  node: HTMLElement,
  spec: ConnectionSpec,
): Coordinate => {
  const rect = node.getBoundingClientRect();
  return connectionCalc[spec.region]({
    top: rect.top + window.scrollY,
    left: rect.left + window.scrollX,
    bottom: rect.bottom + window.scrollY,
    right: rect.right + window.scrollX,
    width: rect.width,
    height: rect.height,
  } as DOMRect);
};

const Network = ({ className }: { className?: string }) => {
  const { refs } = useRefs();

  const { connections } = useConnections();

  const [placements, setPlacements] = useState<Placement[]>([]);

  /* eslint-disable react-hooks/exhaustive-deps */
  // "connections" triggers too many re-renders but its exclusion is non-exhaustive.
  const updatePlacements = useCallback(() => {
    setPlacements(
      connections.reduce((acc: Placement[], { fromId: from, toId: to }) => {
        const fromNode = refs[from];
        const toNode = refs[to];
        if (fromNode && fromNode.current && toNode && toNode.current) {
          acc.push({
            from: calculatePosition(fromNode.current, {
              id: from,
              region: Region.RIGHT_MIDDLE,
            }),
            to: calculatePosition(toNode.current, {
              id: to,
              region: Region.LEFT_MIDDLE,
            }),
          });
        }
        return acc;
      }, []),
    );
  }, [connections.length, refs]);
  /* eslint-enable react-hooks/exhaustive-deps */

  useEffect(() => {
    updatePlacements();

    window.addEventListener("resize", updatePlacements);

    const mainElement = document.querySelector("main");
    mainElement?.addEventListener("scroll", updatePlacements);

    const observer = new MutationObserver(() => updatePlacements());

    observer.observe(document.body, {
      childList: true,
      subtree: true,
      attributes: false,
      characterData: false,
    });

    return () => {
      window.removeEventListener("resize", updatePlacements);
      mainElement?.removeEventListener("scroll", updatePlacements);
      observer.disconnect();
    };
  }, [updatePlacements]);

  return (
    <div className={`${className}`}>
      {placements.map(({ from, to }, idx) => (
        <Arrow from={from} to={to} key={idx} />
      ))}
    </div>
  );
};

export { Network };
