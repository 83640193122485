import useWrappedSWR from "swr";
import { z } from "zod";
import { fetcher } from "../util/fetcher";
import { TrafficMetricsSchema } from "./useDestinationIncomingTraffic";

const IncomingTrafficByPageSchema = z.record(
  z.string(),
  z.record(z.string(), TrafficMetricsSchema),
);
type IncomingTrafficByPage = z.output<typeof IncomingTrafficByPageSchema>;

const useIncomingTrafficByPage = (destinationName: string) => {
  const uri = `page_incoming_traffic?dest_name=${destinationName}`;
  const { data, error, isLoading } = useWrappedSWR(uri, fetcher);
  return {
    incomingTrafficByPage:
      error || isLoading ? {} : IncomingTrafficByPageSchema.parse(data),
    error,
    isLoading,
  };
};

export type { IncomingTrafficByPage };
export { useIncomingTrafficByPage };
