import { Funnel, useFunnels } from "../hooks/useFunnels";
import { TacticChip } from "./TacticChip";
import { useRegisterRef } from "../hooks/useRegisterRef";
import { TimeSeries } from "../charts/TimeSeries";
import { Metric } from "./Metric";
import { Tactic, useTacticsForFunnel } from "../hooks/useTactics";
import { useAdTrafficTimeSeriesForFunnel } from "../hooks/useAdTraffic";
import { getPhosphorIcon } from "../util/getPhosphorIcon";
import { useState } from "react";
import { useTransition, animated } from "@react-spring/web";
import { curveMonotoneX, curveLinear } from "@visx/curve";
import { FocusedMetric } from "../App";

enum TimeSeriesFormat {
  Cumulative,
  Daily,
}

const FunnelContainer = ({
  funnel,
  className,
  timeSeriesFormat,
  focusedMetric,
  setTimeSeriesFormat,
  setFocusedMetric,
}: {
  funnel: Funnel;
  timeSeriesFormat: TimeSeriesFormat;
  focusedMetric: FocusedMetric;
  setTimeSeriesFormat: (x: TimeSeriesFormat) => void;
  setFocusedMetric: (x: FocusedMetric) => void;
  className?: string;
}) => {
  const { funnelName, color, icon } = funnel;
  const { tactics } = useTacticsForFunnel(funnelName);
  const FunnelIcon = getPhosphorIcon(icon);
  const ref = useRegisterRef(funnelName);

  const { timeseries: timeSeriesData, isLoading } =
    useAdTrafficTimeSeriesForFunnel(funnelName);

  const timeSeriesMetric = (() => {
    const format: Record<TimeSeriesFormat, string> = {
      [TimeSeriesFormat.Cumulative]: "cumulative",
      [TimeSeriesFormat.Daily]: "daily",
    };
    const metric: Record<FocusedMetric, string> = {
      [FocusedMetric.Sessions]: "sessions",
      [FocusedMetric.KeyEvents]: "key_events",
      [FocusedMetric.Events]: "events",
    };
    return `${format[timeSeriesFormat]}_${metric[focusedMetric]}`;
  })();

  const friendlyMetric = (() => {
    const metric: Record<FocusedMetric, string> = {
      [FocusedMetric.Sessions]: "Sessions",
      [FocusedMetric.KeyEvents]: "Key Events",
      [FocusedMetric.Events]: "Events",
    };
    return metric[focusedMetric];
  })();

  const friendlyTimeFormat = (() => {
    const format: Record<TimeSeriesFormat, string> = {
      [TimeSeriesFormat.Cumulative]: "Cumulative",
      [TimeSeriesFormat.Daily]: "Daily",
    };
    return format[timeSeriesFormat];
  })();

  const friendlyFunnelName = `${funnelName} Funnel`;

  const totalSessions = tactics.reduce((a, t) => t.totalSessions + a, 0);
  const totalEvents = tactics.reduce((a, t) => t.totalEvents + a, 0);
  const totalKeyEvents = tactics.reduce((a, t) => t.totalKeyEvents + a, 0);

  const tacticMetricValue = (t: Tactic) => {
    const lookup: Record<FocusedMetric, number> = {
      [FocusedMetric.Sessions]: t.totalSessions,
      [FocusedMetric.KeyEvents]: t.totalKeyEvents,
      [FocusedMetric.Events]: t.totalEvents,
    };
    return lookup[focusedMetric];
  };

  const tacticMetricProportion = (t: Tactic) => {
    const lookup: Record<FocusedMetric, number> = {
      [FocusedMetric.Sessions]: t.totalSessions / totalSessions,
      [FocusedMetric.KeyEvents]: t.totalKeyEvents / totalKeyEvents,
      [FocusedMetric.Events]: t.totalEvents / totalEvents,
    };
    return lookup[focusedMetric];
  };

  let height = 0;
  const heightPerChild = 28;
  const transitions = useTransition(
    tactics
      .sort((a, b) => tacticMetricValue(b) - tacticMetricValue(a))
      .map((data) => ({
        ...data,
        y: (height += heightPerChild) - heightPerChild,
      })),
    {
      keys: (tactic) => tactic.tacticName,
      from: { height: 0, opacity: 0 },
      leave: { height: 0, opacity: 0 },
      enter: ({ y }) => ({ y, opacity: 1 }),
      update: ({ y }) => ({ y }),
    },
  );

  return (
    <div
      ref={ref}
      className={`${className} flex flex-col bg-slate-100 rounded-lg`}
    >
      <div
        className={`font-bold bg-[${color}] rounded-t-lg p-2 flex items-center gap-1`}
      >
        <FunnelIcon size={20} />
        {friendlyFunnelName}
      </div>
      <div className="bg-slate-50 h-fit">
        <div
          className="h-40"
          onClick={() => {
            setTimeSeriesFormat(
              timeSeriesFormat === TimeSeriesFormat.Cumulative
                ? TimeSeriesFormat.Daily
                : TimeSeriesFormat.Cumulative,
            );
          }}
        >
          {timeSeriesData && !isLoading ? (
            <TimeSeries
              displayedId={timeSeriesMetric}
              allData={timeSeriesData}
              aggregationDescription={friendlyTimeFormat}
              shortTitle={friendlyFunnelName}
              metricName={friendlyMetric}
              color={color}
              curve={
                timeSeriesFormat === TimeSeriesFormat.Cumulative
                  ? curveMonotoneX
                  : curveLinear
              }
            />
          ) : (
            <></>
          )}
        </div>
        <div className="flex justify-around h-16 text-sm text-slate-400 pb-1 gap-2 px-2">
          <Metric
            name={"Sessions"}
            value={totalSessions}
            isActive={focusedMetric === FocusedMetric.Sessions}
            onClick={() => setFocusedMetric(FocusedMetric.Sessions)}
          />
          <Metric
            name={"Events"}
            value={totalEvents}
            isActive={focusedMetric === FocusedMetric.Events}
            onClick={() => setFocusedMetric(FocusedMetric.Events)}
          />
          <Metric
            name={"Key Events"}
            value={totalKeyEvents}
            isActive={focusedMetric === FocusedMetric.KeyEvents}
            onClick={() => setFocusedMetric(FocusedMetric.KeyEvents)}
          />
        </div>
      </div>
      <div
        className="rounded-b-lg p-2 flex flex-col gap-2 "
        style={{ height: (heightPerChild + 8) * tactics.length + 8 }}
      >
        {transitions((style, tactic, _t, index) => (
          <animated.div
            key={index}
            style={{ zIndex: tactics.length - index, ...style }}
          >
            <TacticChip
              tactic={tactic}
              metricValue={tacticMetricValue(tactic)}
              proportion={tacticMetricProportion(tactic)}
            />
          </animated.div>
        ))}
      </div>
    </div>
  );
};

const AllFunnelsContainer = ({
  focusedMetric,
  setFocusedMetric,
  className,
}: {
  focusedMetric: FocusedMetric;
  setFocusedMetric: (x: FocusedMetric) => void;
  className?: string;
}) => {
  const { funnels } = useFunnels();

  const [timeSeriesFormat, setTimeSeriesFormat] = useState(
    TimeSeriesFormat.Cumulative,
  );

  return (
    <div className={`${className}`}>
      {funnels.map((funnel, idx) => (
        <FunnelContainer
          key={idx}
          className="w-full"
          funnel={funnel}
          timeSeriesFormat={timeSeriesFormat}
          setTimeSeriesFormat={setTimeSeriesFormat}
          focusedMetric={focusedMetric}
          setFocusedMetric={setFocusedMetric}
        />
      ))}
    </div>
  );
};

export { AllFunnelsContainer };
