import { ArrowsClockwise } from "@phosphor-icons/react";
import { getPhosphorIcon } from "../util/getPhosphorIcon";
import { mutate } from "swr";
import { IntegrationStatus, useIntegration, useIntegrationStatus, INTEGRATION_PULL_BASE_ENDPOINT } from "../hooks/useIntegration"

const StatusChip = ({
  status,
  className,
}: {
  status: IntegrationStatus;
  className?: string;
}) => {
  const statusColors = {
    [IntegrationStatus.LIVE]: "bg-emerald-300",
    [IntegrationStatus.DOWN]: "bg-rose-200",
    [IntegrationStatus.WAITING]: "bg-yellow-300",
  };
  const tailwindBgColor = statusColors[status];

  return (
    <div
      className={`${className} ${tailwindBgColor} h-2 w-2 rounded-full`}
    ></div>
  );
};

const Integration = ({
  name,
  icon,
  className,
}: {
  name: string;
  icon: string;
  className?: string;
}) => {
 
  let {isValidating: isValidatingIntegration} = useIntegration(name);
  let {status, isValidating: isValidatingIntegrationStatus} = useIntegrationStatus(name);

  status.status = (isValidatingIntegration 
      // || isValidatingIntegrationStatus // could be also switch on
  ) ? IntegrationStatus.WAITING : status.status;
  
  const LeftIcon = getPhosphorIcon(icon);
  return (
    <div
      className={`${className} text-slate-600 flex items-center gap-2 bg-slate-50 rounded-md p-1 border border-slate-200 
      ${status.status == IntegrationStatus.WAITING ? "animate-pulse bg-yellow-100" : status.status === IntegrationStatus.DOWN ? "bg-rose-100" : ""}`}
    >
      <StatusChip status={status.status} />
      <LeftIcon />
      {name}<span className={'text-xs'}>Last check: {status.lastCheck}</span>&nbsp;
      <button
        className={`p-0.5 border rounded-md border-slate-300 hover:bg-slate-100 ${status.status == IntegrationStatus.WAITING ? "hidden" : ""}`}
        onClick={() => mutate(`${INTEGRATION_PULL_BASE_ENDPOINT}?name=${name}`)}
        disabled={status.status == IntegrationStatus.WAITING}
      >
        <ArrowsClockwise className="text-slate-400" />
      </button>
    </div>
  );
};

export { Integration };
