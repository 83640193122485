import {
  ArrowArcLeft,
  Circle,
  CursorClick,
  Envelope,
  Eye,
  FacebookLogo,
  Funnel,
  GoogleLogo,
  Handshake,
  Icon,
  MagnifyingGlass,
  Table,
  Television,
  UserCircle,
  UserSwitch,
} from "@phosphor-icons/react";

const getPhosphorIcon = (icon: string): Icon => {
  const knownIcons: Record<string, Icon> = {
    "arrow-arc-left": ArrowArcLeft,
    "magnifying-glass": MagnifyingGlass,
    television: Television,
    "user-circle": UserCircle,
    "facebook-logo": FacebookLogo,
    "google-logo": GoogleLogo,
    envelope: Envelope,
    "user-switch": UserSwitch,
    funnel: Funnel,
    "cursor-click": CursorClick,
    eye: Eye,
    handshake: Handshake,
    google: GoogleLogo,
    table: Table,
  };
  if (Object.keys(knownIcons).includes(icon)) {
    return knownIcons[icon];
  }
  return Circle;
};

export { getPhosphorIcon };
