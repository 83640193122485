import React from "react";

const Arrow = ({
  from,
  to,
  color = "#cbd5e1",
}: {
  from: { x: number; y: number };
  to: { x: number; y: number };
  color?: string;
}) => {
  const controlPoint1 = { x: (from.x + to.x) / 2, y: from.y };
  const controlPoint2 = { x: (from.x + to.x) / 2, y: to.y };

  const path = `M${from.x},${from.y} C${controlPoint1.x},${controlPoint1.y} ${controlPoint2.x},${controlPoint2.y} ${to.x},${to.y}`;

  return (
    <svg className="absolute top-0 left-0 pointer-events-none h-screen w-screen ">
      <path
        d={path}
        stroke={color}
        strokeWidth="2"
        fill="none"
        strokeDasharray="5,8" // Defines the length of dashes and gaps
        strokeDashoffset="13" // Sum of stroke Dasharray
        style={{ animation: "dash 1s linear infinite" }}
      />
    </svg>
  );
};

export { Arrow };
