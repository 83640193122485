import useWrappedSWR from "swr";
import { z } from "zod";
import { fetcher } from "../util/fetcher";

const DestinationSchema = z
  .object({
    dest_name: z.string(),
    lane: z.number(),
    order: z.number(),
    image_uri: z.string(),
  })
  .transform(({ dest_name, lane, order, image_uri }) => ({
    destName: dest_name,
    lane: lane,
    order: order,
    imageUri: image_uri,
  }));

type Destination = z.output<typeof DestinationSchema>;

const DestinationsSchema = z.array(DestinationSchema);

const useDestinations = () => {
  const { data, error, isLoading } = useWrappedSWR("destinations", fetcher);
  return {
    destinations: error || isLoading ? [] : DestinationsSchema.parse(data),
    error,
    isLoading,
  };
};

export type { Destination };
export { useDestinations };
