import useWrappedSWR from "swr";
import { z } from "zod";
import { fetcher } from "../util/fetcher";

const DateLineChartDatumSchema = z
  .object({
    date: z
      .string()
      .refine((dateString) => !isNaN(Date.parse(dateString)), {
        message: "Invalid date string",
      })
      .transform((x) => new Date(x)),
  })
  .catchall(z.number());
const DateLineChartDataSchema = z.array(DateLineChartDatumSchema);

type DateLineChartDatum = z.output<typeof DateLineChartDataSchema>;
type DateLineChartData = z.output<typeof DateLineChartDataSchema>;

const useAdTrafficTimeSeriesForFunnel = (funnelName: string) => {
  const { data, error, isLoading } = useWrappedSWR(
    `funnel_traffic_time_series?funnel_name=${funnelName}`,
    fetcher,
  );
  return {
    timeseries: error || isLoading ? [] : DateLineChartDataSchema.parse(data),
    error,
    isLoading,
  };
};

export type { DateLineChartData, DateLineChartDatum };
export { useAdTrafficTimeSeriesForFunnel };
