import React, {
  createContext,
  useContext,
  useRef,
  ReactNode,
  MutableRefObject,
} from "react";

type RefsContextType = {
  refs: { [key: string]: MutableRefObject<HTMLElement | null> };
  registerRef: (id: string, ref: MutableRefObject<HTMLElement | null>) => void;
};

const RefContext = createContext<RefsContextType | undefined>(undefined);

export const RefProvider = ({ children }: { children: ReactNode }) => {
  const refs = useRef<{ [key: string]: MutableRefObject<HTMLElement | null> }>(
    {},
  );

  const registerRef = (
    id: string,
    ref: MutableRefObject<HTMLElement | null>,
  ) => {
    refs.current[id] = ref;
  };

  return (
    <RefContext.Provider value={{ refs: refs.current, registerRef }}>
      {children}
    </RefContext.Provider>
  );
};

export const useRefs = (): RefsContextType => {
  const context = useContext(RefContext);
  if (!context) {
    throw new Error("useRefs must be used within a RefProvider");
  }
  return context;
};
