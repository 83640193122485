import useSWR, { SWRConfiguration } from "swr";

const baseUrl = `${window.__RUNTIME_CONFIG__.API_URL}/api`;

const useWrappedSWR = (url?: string, options?: SWRConfiguration) => {
  return useSWR(url, fetcher, {
    onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
      // retry after 0.5 seconds if the error status was 500
      if (error.status === 500)
        setTimeout(() => revalidate({ retryCount }), 500);
      else return;
    },
    ...options,
  });
};

const fetcher = (path: string) => {
  const uri = `${baseUrl}/${path}`;
  return fetch(uri)
    .then((res) => res.json())
    .catch((err) => {
      console.error(err);
      throw err;
    });
};

export { fetcher, useWrappedSWR, baseUrl };
