import useWrappedSWR from "swr";
import { z } from "zod";
import { fetcher } from "../util/fetcher";

const TacticSchema = z
  .object({
    color: z.string(),
    funnel_name: z.string(),
    icon: z.string(),
    medium_name: z.string(),
    source_name: z.string(),
    tactic_name: z.string(),
    utm_medium: z.string(),
    utm_source: z.string(),
    total_events: z.number(),
    total_sessions: z.number(),
    total_key_events: z.number(),
  })
  .transform(
    ({
      color,
      funnel_name,
      icon,
      medium_name,
      source_name,
      tactic_name,
      utm_medium,
      utm_source,
      total_events,
      total_sessions,
      total_key_events,
    }) => ({
      color: color,
      funnelName: funnel_name,
      icon: icon,
      mediumName: medium_name,
      sourceName: source_name,
      tacticName: tactic_name,
      utmMedium: utm_medium,
      utmSource: utm_source,
      totalEvents: total_events,
      totalSessions: total_sessions,
      totalKeyEvents: total_key_events,
    }),
  );

type Tactic = z.output<typeof TacticSchema>;

const TacticsSchema = z.array(TacticSchema);

const useTactics = () => {
  const { data, error, isLoading } = useWrappedSWR(`tactics`, fetcher);
  return {
    tactics: error || isLoading ? [] : TacticsSchema.parse(data),
    error,
    isLoading,
  };
};

const useTacticsForFunnel = (funnelName: string) => {
  const { data, error, isLoading } = useWrappedSWR(
    `tactics?funnel_name=${funnelName}`,
    fetcher,
  );
  return {
    tactics: error || isLoading ? [] : TacticsSchema.parse(data),
    error,
    isLoading,
  };
};

export type { Tactic };
export { useTactics, useTacticsForFunnel };
