import React from "react";
import { scaleLinear, scaleTime } from "@visx/scale";
import { AxisBottom, AxisLeft } from "@visx/axis";
import { AreaClosed, Circle, LinePath } from "@visx/shape";
import { useParentSize } from "@visx/responsive";
import { GridRows } from "@visx/grid";
import { format } from "d3-format";
import { curveMonotoneX } from "@visx/curve";
import { useTooltip } from "@visx/tooltip";
import { localPoint } from "@visx/event";
import { CurveFactory } from "d3-shape";
import { bisector } from "d3-array";
import { timeFormat } from "d3-time-format";
import { DateLineChartData } from "../hooks/useAdTraffic";

const margin = { top: 4, right: 20, bottom: 36, left: 30 };
interface Datum {
  x: Date;
  y: number;
}

const TimeSeries = ({
  className,
  displayedId,
  allData,
  shortTitle,
  metricName,
  aggregationDescription,
  color,
  curve = curveMonotoneX,
  dateRangeStart = undefined,
  dateRangeEnd = undefined,
  xNumTicks = 2,
  yNumTicks = 3,
}: {
  className?: string;
  displayedId: string;
  allData: DateLineChartData;
  shortTitle: string;
  metricName: string;
  aggregationDescription: string;
  color: string;
  curve?: CurveFactory;
  dateRangeStart?: Date;
  dateRangeEnd?: Date;
  xNumTicks?: number;
  yNumTicks?: number;
}) => {
  const { tooltipData, showTooltip, hideTooltip } = useTooltip<Datum>();

  const { parentRef, width, height } = useParentSize({ debounceTime: 0.5 });

  const innerWidth = Math.max(0, width - margin.left - margin.right);
  const innerHeight = Math.max(0, height - margin.top - margin.bottom);
  const numberTickFormatter = format(".2~s");

  // TODO: Refactor to remove this unnecessary O(n)
  const data: Datum[] = allData.map((d) => ({ x: d.date, y: d[displayedId] }));

  const xScale = scaleTime({
    domain: [
      dateRangeStart || data[0].x,
      dateRangeEnd || data[data.length - 1].x,
    ],
    range: [0, innerWidth],
  });

  const yScale = scaleLinear({
    domain: [0, Math.max(...data.map((d) => d.y))],
    range: [innerHeight, 0],
  });

  const title = tooltipData
    ? `${format(",.2d")(tooltipData.y)} ${aggregationDescription} ${metricName} ${timeFormat("%b %e, %Y")(tooltipData.x)}`
    : `${aggregationDescription} ${shortTitle} ${metricName}`;

  const onMouseMove = (event: React.MouseEvent<SVGGElement, MouseEvent>) => {
    if (!event.currentTarget.ownerSVGElement) return;
    const pNoMargin = localPoint(event.currentTarget.ownerSVGElement, event);
    if (!pNoMargin) return;
    const p = {
      x: pNoMargin.x - margin.left,
      y: pNoMargin.y - margin.top,
    };
    if (p.x < 0) return;
    const hoveredDate = xScale.invert(p.x);

    const bisectDate = bisector((d: Datum) => d.x).left;
    const index = bisectDate(data, hoveredDate);
    const getClosestDataPoint = (
      data: Datum[],
      index: number,
      hoveredDate: Date,
    ) => {
      const prev = data[index - 1];
      const curr = data[index];

      return Math.abs(hoveredDate.getTime() - prev.x.getTime()) <
        Math.abs(curr.x.getTime() - hoveredDate.getTime())
        ? prev
        : curr;
    };

    const closestPoint =
      index === 0
        ? data[0]
        : index === data.length
          ? data[data.length - 1]
          : getClosestDataPoint(data, index, hoveredDate);

    showTooltip({
      tooltipLeft: p.x,
      tooltipTop: p.y,
      tooltipData: closestPoint,
    });
  };

  return (
    <div className={`${className} w-full h-full flex flex-col`} ref={parentRef}>
      <div
        className={`flex justify-center text-sm ${tooltipData ? "font-semibold" : " "} text-slate-400 italic`}
      >
        {title}
      </div>
      <svg width={width} height={height}>
        <g
          transform={`translate(${margin.left},${margin.top})`}
          onMouseMove={onMouseMove}
          onMouseLeave={() => hideTooltip()}
        >
          <rect width={innerWidth} height={innerHeight} fill="transparent" />
          <GridRows
            scale={yScale}
            width={innerWidth}
            height={innerHeight}
            numTicks={yNumTicks}
            numOctaves={3}
          />
          <AxisBottom
            axisClassName="select-none"
            tickLabelProps={{
              fontSize: 10,
              fontStyle: "normal",
              fontFamily: "Lato",
              fill: "#94a3b8",
            }}
            numTicks={xNumTicks}
            scale={xScale}
            hideAxisLine
            top={innerHeight - 10}
            hideTicks
          />
          <AxisLeft
            axisClassName="select-none"
            scale={yScale}
            hideTicks
            numTicks={yNumTicks}
            tickLabelProps={{
              fontSize: 10,
              fontStyle: "normal",
              fontFamily: "Lato",
              textAnchor: "end",
              fill: "#94a3b8",
            }}
            left={4}
            hideAxisLine
            tickFormat={(x) => numberTickFormatter(x)}
          />
          <AreaClosed
            data={data}
            x={(d) => xScale(d.x)}
            y={(d) => yScale(d.y)}
            yScale={yScale}
            fill={color}
            fillOpacity={0.14}
            curve={curve}
          />
          <LinePath
            data={data}
            x={(d) => xScale(d.x)}
            y={(d) => yScale(d.y)}
            stroke={color}
            strokeWidth={2}
            strokeOpacity={0.8}
            curve={curve}
          />
          {data.map(({ x, y }, idx) => (
            <Circle
              key={idx}
              cx={xScale(x)}
              cy={yScale(y)}
              r={tooltipData && tooltipData.x === x ? 4 : 2}
              fill={color}
            />
          ))}
        </g>
      </svg>
    </div>
  );
};

export { TimeSeries };
